<template>
    <div class="other_page_r">
        <el-card class="card_box">
            <div slot="header" class="card_title">
                <span>认证审核管理</span>
            </div>
            <div>
                <el-form :inline="true" :model="search" class="demo-form-inline">
                    <!-- <el-form-item label="名称">
                        <el-input class="custom_input_170" v-model="searchInfo.truename" placeholder=""></el-input>
                    </el-form-item> -->
                    <el-form-item label="ID">
                        <el-input class="custom_input_170" v-model="searchInfo.uid" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="认证类型">
                        <el-select class="custom_input_170" v-model="searchInfo.auth_type" placeholder="">
                            <el-option label="个人" value="1"></el-option>
                            <el-option label="企业" value="2"></el-option>
                            <el-option label="项目" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="认证状态">
                        <el-select class="custom_input_170" v-model="searchInfo.check_authentication" placeholder="">
                            <el-option label="通过" value="1"></el-option>
                            <el-option label="审核中" value="2"></el-option>
                            <el-option label="拒绝" value="3"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="searchBtn">查询</el-button>
                        <el-button type="primary" @click="reset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box">
                <tablec :tableData="tableData"></tablec>
            </div>
            <div class="flex justify_content_end aligin_items_c table_footer custom_btn">


                <pagination v-show="total > 0" :total="total" :page.sync="searchInfo.page" :limit.sync="searchInfo.size"
                    @pagination="getDataList" />

            </div>
        </el-card>
    </div>
</template>

<script>
    import tablec from "./tablec.vue"
    import { authLists } from "@/api/examine";
    import { searchMixin } from "@/utils/searchMixin.js";
    export default {
        mixins: [searchMixin],
        components: { tablec },
        name: '',
        data() {
            return {
                tableData: [],
                total: Number,
                searchInfo: {
                    page: 1,
                    size: 10,
                    // truename: '',
                    uid: '',
                    auth_type: '',
                    check_authentication: ''
                },

            }
        },
        created() {
            this.getDataList()
        },
        methods: {
            searchBtn(){
                this.searchInfo.page=1
                this.getDataList()
            },
            getDataList() {
                authLists(this.searchInfo).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.list
                        this.total = res.data.total;
                    } else {

                        this.tableData = []
                        this.total = 0;
                        
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.$message.error(error.message);
                });
            }
        }

    }
</script>

<style scoped>

</style>