<template>
    <div class="table">
        <el-table border ref="multipleTable" :data="tableData" :header-cell-style="{'text-align':'center'}"
        height="60vh"
            :cell-style="{'text-align':'center'}" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>

            <el-table-column prop="truename" label="名称" width="120">
            </el-table-column>
            <el-table-column prop="uid" label="用户ID" width="120">
            </el-table-column>
            <el-table-column label="认证类型">
                <template slot-scope="scope">
                    {{scope.row.auth_type==1?"个人":scope.row.auth_type==2?"企业":"项目"}}
                </template>
            </el-table-column>
            <el-table-column label="认证状态">
                <template slot-scope="scope">
                    {{scope.row.check_authentication==1?"通过":scope.row.check_authentication==2?"审核中":"拒绝"}}
                </template>
            </el-table-column>
            <el-table-column prop="project_phone" label="联系方式">
            </el-table-column>
            <el-table-column prop="project_name" label="项目名称">
            </el-table-column>
            <el-table-column prop="submit_time" label="提交时间">
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="190">
                <template slot-scope="scope">
                    <span class="pointer" @click="pass(scope.row,1)" v-if="scope.row.check_authentication!=2">通过</span>
                    <span class="pointer margin_left_10" @click="pass(scope.row,3)" v-if="scope.row.check_authentication!=2">不通过</span>
                    <span class="pointer margin_left_10" @click="toDetail(scope.row)">详情</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="addLabelCategoryDialog">
            <el-dialog :title="title" :visible.sync="dialog" :modal-append-to-body='false'>
                <div class="folder">
                    <el-form label-position="right" label-width="120px" :model="stateObj">
                        <el-form-item label="原因：" class="margin_top_20">
                            <el-input v-model="stateObj.auth_remark" class="custom_input_350" type="textarea"
                                :rows="4"></el-input>
                        </el-form-item>

                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">

                    <el-button type="primary" class="saveBtn" @click="submit">保存</el-button>
                    <el-button @click="dialog = false" class="cancel">取 消</el-button>
                </span>
            </el-dialog>
        </div>

    </div>
</template>

<script>
    import { authLists, examine } from "@/api/examine";
    export default {
        props: ["tableData"],
        name: '',
        data() {
            return {
                value: true,
                title: "",
                dialog: false,
                stateObj: {
                    id: "",
                    check_authentication: "",
                    auth_remark: ""
                }
            }
        },
        methods: {
            toDetail(row) {
                if (row.auth_type == 1) {
                    this.$router.push({
                        path: "/addpersonal",
                        query: { id: row.uid, type: "detail" }
                    })
                } else if (row.auth_type == 2) {
                    this.$router.push({
                        path: "/addfirm",
                        query: { id: row.uid, type: "detail" }
                    })
                } else {
                    this.$router.push({
                        path: "/addnormal",
                        query: { id: row.uid, type: "detail" }
                    })
                }

            },
            pass(row, state) {
                if (state == 1) {
                    this.title = "通过"
                } else {
                    this.title = "拒绝"
                }
                this.stateObj.id=row.id
                this.stateObj.check_authentication=state

               
                this.dialog = true
            },
            submit(){
                const that=this
                examine(this.stateObj).then((res) => {
                    if (res.code == 200) {
                        that.$parent.$parent.getDataList()
                        this.dialog = false
                    } else {
                        that.$message.error(res.message);
                    }
                }).catch((error) => {
                    this.$message.error(error.message);
                });
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }
        }
    }
</script>

<style>
    .addLabelCategoryDialog .el-dialog {
        width: 600px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.29);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
    }
</style>