import request from "@/utils/request";

//   用户审核列表

export function authLists(obj) {
	return request({
		url: "/admin/users/authLists",
		method: "post",
		data: obj,
	});
}
//   审核认证
export function examine(obj) {
	return request({
		url: "/admin/users/examine",
		method: "post",
		data: obj,
	});
}
//   信息审核列表

export function authColumnList(obj) {
	return request({
		url: "/admin/users/authColumnList",
		method: "post",
		data: obj,
	});
}
